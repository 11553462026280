@tailwind base;
@tailwind components;
@tailwind utilities;

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

//EN: Try not to use this file for global styling
//Use the GlobalStyles component instead as this allows us to create global styles from the theme
html,
body {
}
